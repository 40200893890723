import LoginComponent from './views/Login/Login.vue';
import TFAComponent from './views/TFA/TFA.vue';

const authRoutes = [
  {
    path: '/auth/login/:email?',
    name: 'LoginComponent',
    props: true,
    component: LoginComponent,
    meta: { isPublicPage: true },
  },
  {
    path: '/auth/token/:userId',
    name: 'TFAComponent',
    props: true,
    component: TFAComponent,
    meta: { isPublicPage: true },
  },
];

export default authRoutes;
