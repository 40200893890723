<template>
  <div>
    <a-timeline>
      <a-timeline-item
        v-for="workflow in workflows"
        :key="workflow._id"
        :color="workflow.activ ? 'green' : ''"
        @click="openWorkflow(workflow)"
        class="pointer"
      >
        <template #dot>
          <CheckCircleOutlined v-if="workflow.activ" />
          <ExclamationCircleOutlined v-else />
        </template>
        <div>
          <span :class="workflowId === workflow._id ? 'strong' : ''">
            {{ workflow.name }}
          </span>
        </div>

        <a-tag :color="workflow.activ ? 'green' : 'red'"> {{ workflow.activ ? 'Enabled' : 'Disabled' }} </a-tag>

        <div v-for="(event, key) in workflow.events" :key="event._id" @click="openEvent(event)" style="margin: 0">
          <div class="workflows" :style="selectedEventId === event._id ? 'color:#000' : ''">
            <span :style="selectedEventId === event._id ? 'font-weight: bold' : ''">
              {{ key + 1 }}.
              {{ event.name }}
            </span>
          </div>
        </div>

        <a-button type="link" @click="openWorkflowSettings(workflow)" class="actions__pull-right-top" size="small"><SettingOutlined /></a-button>
      </a-timeline-item>

      <a-timeline-item>
        <template #dot>
          <LoadingOutlined v-if="loadingWorkflow" />
          <PlusCircleOutlined v-else />
        </template>
        <a @click="createWorkflow()">Add new workflow</a>
      </a-timeline-item>
    </a-timeline>

    <teleport to="body">
      <a-modal v-model:open="openedWorkflow" @close="closeWorkflowSettings" :title="selectedWorkflow?.name">
        <a-divider />
        <a-input placeholder="Workflow name" v-model:value="editSelectedWorkflowForm.name" />
        <div style="padding-top: 10px">
          Active
          <a-switch checked-children="YES" v-model:checked="editSelectedWorkflowForm.activ" un-checked-children="NO"></a-switch>
        </div>
        <template #footer>
          <a-divider />
          <a-popconfirm placement="topLeft" title="Are you sure delete this event?" ok-text="Yes" cancel-text="No" @confirm="removeAction">
            <a-button :loading="loadingRemoveWorkflow" class="float-left" type="link" danger>
              <template #icon>
                <DeleteOutlined />
              </template>
              Delete
            </a-button>
          </a-popconfirm>
          <a-button @click="closeWorkflowSettings">Cancel</a-button>
          <a-button :loading="loadingSaveChanges" @click="saveChanges" type="primary">SAVE</a-button>
        </template>
      </a-modal>
    </teleport>
  </div>
</template>

<script>
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue';
import { WorkflowActions, WorkflowGetters } from '@workflow/shared/workflow.store';
import router from '@/router';

export default {
  props: ['workflowGroupId', 'workflowId', 'selectedEventId', 'workflows'],
  emits: ['open:event', 'open:workflow', 'autosave'],
  watch: {
    workflowId(value) {
      this.selectedWorkflow = WorkflowGetters.getSelectedWorkflow(value);
    },
    selectedWorkflow(value) {
      this.editSelectedWorkflowForm = {
        ...(value ?? {}),
      };
    },
  },
  data() {
    return {
      openedWorkflowId: null,
      openedWorkflow: false,

      editSelectedWorkflowForm: {
        ...(this.selectedWorkflow ?? {}),
      },

      loadingSaveChanges: false,
      loadingRemoveWorkflow: false,

      selectedWorkflow: null,
      loadingWorkflow: false,
      workflow: {
        _id: this.workflowGroupId,
        name: 'New workflow name',
      },
      eventId: this.$route.params.eventId ? this.$route.params.eventId : 0,
    };
  },
  created() {
    this.selectedWorkflow = WorkflowGetters.getSelectedWorkflow(this.workflowId);
  },
  methods: {
    openEvent(event) {
      const elem = document.getElementById(`event_${event._id}`);
      // const el = this;
      if (elem) {
        setTimeout(function () {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 200);
      } else {
        setTimeout(function () {
          const elem2 = document.getElementById(`event_${event._id}`);
          if (elem2) {
            elem2.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 600);
      }
      this.$emit('open:event', event._id);
    },
    openWorkflowSettings(workflow) {
      this.openedWorkflowId = workflow._id;
      this.openedWorkflow = true;
    },
    closeWorkflowSettings() {
      this.openedWorkflowId = null;
      this.openedWorkflow = false;
    },

    openWorkflow(event) {
      this.$emit('open:workflow', event);
    },
    async createWorkflow() {
      this.loadingWorkflow = true;

      await WorkflowActions.addOneWorkflow(this.workflow);

      this.loadingWorkflow = false;
      this.$emit('autosave', false);
    },
    async saveChanges() {
      this.loadingSaveChanges = true;
      await WorkflowActions.editSelectedWorkflow(this.selectedWorkflow, {
        ...this.editSelectedWorkflowForm,
      });
      this.closeWorkflowSettings();
      this.loadingSaveChanges = false;
    },
    removeAction() {
      this.loadingRemoveWorkflow = true;
      WorkflowActions.removeOneWorkflow(this.openedWorkflowId);
      this.loadingRemoveWorkflow = false;
      this.closeWorkflowSettings();
      router.push({
        name: 'WorkflowBuilder',
        params: { workflowGroupId: this.workflowGroupId },
      });
    },
  },
  components: {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusCircleOutlined,
    SettingOutlined,
    DeleteOutlined,
  },
};
</script>

<style scoped>
.actions__pull-right-top {
  position: absolute !important;
  top: 0px;
  right: 0px;
  float: right;
}

.workflows {
  position: relative;
}
.workflows::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 8px;
  display: block;
  background-color: #e8e8e8;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
