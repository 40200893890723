function isValid(password) {
  const hasLength = password.length > 8;
  const hasCapital = /[A-Z]/.test(password);
  const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasNumbers = /[0-9]/.test(password);
  return hasLength && hasCapital && hasSpecial && hasNumbers;
}

function evaluate(password) {
  let strength = 0;

  // Check for length
  if (password.length >= 8) {
    strength += 1;
  }
  if (password.length >= 12) {
    strength += 1;
  }

  // Check for uppercase letters
  if (/[A-Z]/.test(password)) {
    strength += 1;
  }

  // Check for lowercase letters
  if (/[a-z]/.test(password)) {
    strength += 1;
  }

  // Check for numbers
  if (/[0-9]/.test(password)) {
    strength += 1;
  }

  // Check for special characters
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    strength += 1;
  }

  // Determine strength level
  if (strength >= 5) {
    return 'Strong';
  }
  if (strength >= 3) {
    return 'Moderate';
  }
  return 'Weak';
}

export default {
  isValid,
  evaluate,
};
