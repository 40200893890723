import { UserGetters, UserActions } from './user.store';

export const authGuard = async (to, from, next) => {
  if (to.meta && to.meta.isPublicPage) {
    return next();
  }

  if (!UserGetters.getUser()) {
    const currentRoute = window.location.pathname + window.location.search + window.location.hash;

    try {
      await UserActions.silentLogin();

      if (!UserGetters.getUser()) {
        return next(`/auth/login?redirect=${encodeURIComponent(currentRoute)}`);
      }
      return next();
    } catch (error) {
      return next(`/auth/login?redirect=${encodeURIComponent(currentRoute)}`);
    }
  }

  return next();
};
